import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Text
} from "@chakra-ui/react";

import {
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import Layout from "../components/Layout";
import Head from "../components/Head";

import { Carousel } from "../components/Carousel";
import { PageHero } from "../components/Hero";
import { Scroller } from "../components/Scroller";
import { Stack } from "../components/Stack";


const CareersPage: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query {
      page: mdx(
        frontmatter: {
          title: {
            eq: "Careers"
          }
        }
      ) {
        frontmatter {
          seo_title
          seo_description
          heading
          subheading
        }
      }

      projects: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "project"
            }
            featured: {
              eq: true
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              project_type
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      services: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "service"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              order
            }
            slug
          }
        }
      }

      teamMembers: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "team-member"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              name
              position
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              avatar {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title={ data.page.frontmatter.seo_title }
        description={ data.page.frontmatter.seo_description }
      />

      <SectionWrapper
        background="background.aliceblue"
        padding="153px 0 138px"
      >
        <SectionContainer>
          <PageHero
            heading={ data.page.frontmatter.heading }
            description={ data.page.frontmatter.subheading }
            alignment="center"
            HeroComponent={
              <Stack
                nodes={[1]}
                layout="contact"
                avatars={ data.teamMembers.edges }
                alignment="center"
              />
            }
          />
        </SectionContainer>
      </SectionWrapper>

      {/*
      <SectionWrapper
        padding="153px 0 100px"
      >
        <SectionContainer>
          <Scroller
            nodes={ data.teamMembers.edges }
            layout="team"
            heading={
              <>
                Our <Text as="span" color="primary.900">Team</Text>
              </>
            }
          />
        </SectionContainer>
      </SectionWrapper>
      */}

      <SectionWrapper
        padding="110px 0"
      >
        <Carousel
          nodes={ data.projects.edges }
          options={{
            loop: true
          }}
          template="project"
          heading="Featured Projects"
          headingAlignment="space-between"
          headingWidth={{
            base: '100%',
            lg: '600px'
          }}
          description="We're proud of the work we've done to help transform the Greater Toronto Area. Here are a few of the projects that have made their mark on the city."
          slug="/portfolio"
          buttonText="Explore More Projects"
        />
      </SectionWrapper>
    </Layout>
  )
}


export default CareersPage;
