import * as React from "react";
import {
  VStack
} from "@chakra-ui/react";

import {
  Contact
} from "./Layouts";


interface Props {
  nodes: any[]
  layout: string | undefined
  avatars?: any[] | undefined
  alignment?: "center" | undefined
  hasButton?: boolean | undefined
}


export const Stack: React.FC<Props> = ( props ) => {
  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.layout ) {
      case 'contact':
        return (
          <Contact
            key={ _index }
            node={ node.node }
            avatars={ props.avatars }
            alignment={ props.alignment }
            hasButton={ props.hasButton }
          />
        );
      default:
        return null;
    }
  })

  return (
    <VStack
      justify={ props.alignment ? 'center' : 'flex-start' }
      align={ props.alignment ? 'center' : 'flex-start' }
      spacing="48px"
      textAlign={ props.alignment ? 'center' : 'left' }
    >
      { nodeList }
    </VStack>
  )
}
