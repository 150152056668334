import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Heading,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  node: any
  avatars?: any[] | undefined
  alignment?: "center" | undefined
  hasButton?: boolean | undefined
}


export const Contact: React.FC<Props> = ( props ) => {
  return (
    <>
      <VStack
        justify={ props.alignment ? 'center' : 'flex-start' }
        align={ props.alignment ? 'center' : 'flex-start' }
        spacing="8px"
      >
        <Text
          color="primary.900"
          fontSize="16px"
          fontWeight={ 700 }
        >
          ~
        </Text>
        {/*
        <AvatarGroup
          size="md"
          max={ 3 }
        >
          { props.avatars && props.avatars.map( ( node: any, _index: number ) => (
            <Avatar
              name={ node.node.frontmatter.name }
              src={ node.node.frontmatter.avatar.publicURL }
              borderColor="background.aliceblue"
            />
          ))}
        </AvatarGroup>
        */}
        <Heading
          as="h3"
          color="text.primary"
          fontSize="20px"
          fontWeight={ 700 }
        >
          <Link
            href="mailto:info@drobotconstruction.com"
          >
            info@drobotconstruction.com
          </Link>
        </Heading>
        <Box>
          <Text
            color="text.secondary"
            fontSize="16px"
            fontWeight={ 400 }
          >
            We're available 24/7
          </Text>
          <Text
            display={{
              base: 'none',
              lg: 'block'
            }}
            color="text.secondary"
            fontSize="16px"
            fontWeight={ 400 }
          >
            +1 647 721 6065
          </Text>
          <Text
            display={{
              lg: 'none'
            }}
            color="text.secondary"
            fontSize="16px"
            fontWeight={ 400 }
          >
            <Link
              href="tel:+16477216065"
            >
              +1 647 721 6065
            </Link>
          </Text>
        </Box>
      </VStack>
      <VStack
        justify={ props.alignment ? 'center' : 'flex-start' }
        align={ props.alignment ? 'center' : 'flex-start' }
        spacing="8px"
      >
        <Heading
          as="h3"
          color="text.primary"
          fontSize="20px"
          fontWeight={ 700 }
        >
          <Link
            href="mailto:careers@drobotconstruction.com"
          >
            careers@drobotconstruction.com
          </Link>
        </Heading>
        <Text
          color="text.secondary"
          fontSize="16px"
          fontWeight={ 400 }
        >
          If you wish to join us.
        </Text>
        { props.hasButton &&
          <Button
            as={ GatsbyLink }
            to="/careers"
            variant="solid"
            h="42px"
            background="primary.900"
            borderRadius="21px"
            borderColor="primary.900"
            color="white"
            fontSize="14px"
            fontWeight={ 500 }
          >
            View all positions
          </Button>
        }
      </VStack>
    </>
  )
}
